<template>
<div class="student-id-verification-policy section container">

  <div class="row pt-5">
      <div class="clearfix">

        <header class="header mb-4">
          <h1 class="display-2">Student ID Verification Policy</h1>
        </header>
        
  <main id="main-content">

  <p class="lead">To protect the privacy of student information and ensure academic integrity, CSU Global students may encounter BioSig technology in their course assignments. To move forward with
an assignment, a student must successfully complete the BioSig ID verification. If a student fails or refuses to complete the verification sign in, the student will be denied access to the specific
assignment and directed to the appropriate office for validation.</p>
    <p class="lead">If it is determined that anyone other than the student is accessing the student’s profile and
assignments, the information will be reported to the Office of Student Affairs and Provost
which may result in disciplinary action.</p>

<section class="faq section" id="faq" aria-labelledby="faq-heading">

<div class="container px-4 py-5">
  <div class="text-center">
  <h2 id="faq-heading" class="display-3">FAQ</h2>

  </div>
         <div class="row justify-content-center">
           <div class="col-md-8">

         <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h3 class="accordion-header" id="flush-headingOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        What Is an IP address?
      </button>
    </h3>
    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">IP stands for Internet Protocol and is a unique identifying number assigned to every device connected to the Internet. Computers that communicate over the internet of via local networks share information to a specific location using IP addresses. Common tasks for an IP address include both the identification of a host or a network, or identifying the location of a device. An IP address is not random. The creation of an IP address has the basis of math.
        </div>
    </div>
  </div><!--end accordion item-->

  <div class="accordion-item">
    <h3 class="accordion-header" id="flush-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
        What does it mean to mask an IP address?
      </button>
    </h3>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">When an IP address is masked it provides basic anonymity by hiding the IP addresses. IP masking is best suited for those who only require temporary anonymity. IP masking can be done through proxy servers or Tor networks or along with a VPN.
        </div>
    </div>
  </div><!--end accordion item-->

  <div class="accordion-item">
    <h3 class="accordion-header" id="flush-headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
        What does it mean when an IP conflict occurs?
      </button>
    </h3>
    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">An IP conflict occurs when a student’s account is accessed from a device in one location and then accessed from another device and location, where travel between the locations is impossible in the time that has elapsed between logins.
        </div>
    </div>
  </div><!--end accordion item-->

  <div class="accordion-item">
    <h3 class="accordion-header" id="flush-headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
        Can I use a VPN to access my CSU Global courses?
      </button>
    </h3>
    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Yes, students can use a VPN to access courses. Student do need to ensure that their VPN is not masking IP addresses as well. The use of a VPN alone is not a violation of the Student ID Verification policy. </div>
    </div>
  </div><!--end accordion item-->

  <div class="accordion-item">
    <h3 class="accordion-header" id="flush-headingFive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
        Can I give my parents, partner, or tutor my Canvas logins?
      </button>
    </h3>
    <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">No, providing login credentials to anyone violates the CSU Global Code of Conduct and will result in consequences. As a CSU Global student, you are the only one who should have access to your courses and there are no exceptions.
      </div>
    </div>
  </div><!--end accordion item-->

  <div class="accordion-item">
    <h3 class="accordion-header" id="flush-headingSix">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
        Can accessing my course from different devices cause an IP conflict?
      </button>
    </h3>
    <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">No, an IP conflict occurs when a Canvas account is accessed from locations.
      </div>
    </div>
  </div><!--end accordion item-->

  <div class="accordion-item">
    <h3 class="accordion-header" id="flush-headingSeven">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
        I use a work computer and I don’t know if IP addresses are masked.
      </button>
    </h3>
    <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">You can check your computer to see if it is masking. If you do not have permission to confirm then you can work with your employer’s IT department.
      </div>
    </div>
  </div><!--end accordion item-->

  <div class="accordion-item">
    <h3 class="accordion-header" id="flush-headingEight">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
        What Can I do to Ensure I am Following the Policy?
      </button>
    </h3>
    <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body"><ul><li>Understand if any of the devices you use for school work mask IP addresses. Avoid using
any device that is masking to access your Canvas account.</li><li>Do not share your Canvas login information with anyone.</li></ul>
      </div>
    </div>
  </div><!--end accordion item-->

</div><!--end accordion-->










           </div><!--end accordion col-->
         </div><!--end row-->
        </div><!--end container-->
    </section><!--end section--> 


  </main>

      </div><!--/clearfix-->
  </div><!--/row-->
  </div>
  <!--End Container -->

<aside>
  <AdvisorCallToAction />
</aside>
</template>


<script>
// @ is an alias to /src
import AdvisorCallToAction from "@/components/AdvisorCallToAction.vue";

export default {
  name: "AcademicDishonesty",
  components: {
    AdvisorCallToAction,
  },
   mounted() {
    document.title = 'Academic Dishonesty | CSU Global Appeals Center';
        
    },
};
</script>

<style scoped lang="scss">
.accordion-button {
  color:#aa1d40;
  background-color:#f1f2f4;
}

.accordion-button:not(.collapsed) {
  color:#fff;
  background-color:#9e1831;
}

.accordion-item {
  border:0px;
  border-radius:4px;
  margin-bottom:0.5rem;
}

.accordion-body {
  margin-left:2rem;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:focus {
  box-shadow:none;
}

.accordion-collapse {
  background:#f1f2f4;
}

#faq-heading:after {
    content: "";
    display: block;
    margin: 20px auto;
    position: relative;
    width: 60px;
    height: 3px;
    background-color: #ddd;
    margin-top: 10px;
}
</style>


